body {
  overflow: scroll;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 2s;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  /* padding-bottom: 2000px; */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(-20deg);
  }
  50% {
    transform: rotate(20deg);
  }
  to {
    transform: rotate(-20deg);
  }
}


.bio-headline-row {
  display: flex;
  flex-wrap: wrap;
}

.bio-headline-body {
  flex: 1;
}

.bio-headline-photo {
  flex: 0.5;
}

@media (max-width: 500px) {
  .bio-headline-photo {
    flex: 0.5;
    max-width: 50%;
  }
}

.bio-aboutme {
  margin: 2%;
  text-align: justify;
}


.bio-links {
  margin-left: 10px;
  display: flex;
}

.bio-links-col {
  max-height: 50px;
  flex: 1;
  margin-right: 10px;
}

.tech-div {
  display: flex;
  margin: 1%;
  padding-bottom: 15px;
  justify-content: space-around;
  color: white;
  flex-wrap: wrap;
}

.tech-logo {
  max-height: 100px;
  max-width: 100px;
  flex: 1;
  margin-right: 20px;
}