body {
    overflow: auto;
    background: #282c34;
    
}

h1.entrance {
    margin: auto;
    width: 50%;
}

.mainDiv {
    
    background-image: url('./images/cs_bg.jpg');
    position: relative;
    min-height: 95dvh;
    margin: 1%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.innerDiv {
    /* position: static; */
    background: transparent;
    padding: 1%;

    /* margin: auto;
    width: 50%;
    height: 50%; */
    /* top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%); */
}

.buttonDiv {
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    width: 50%;
    animation: buttons_enter 2s;
    position: relative;
}

.buttonFlex {
    flex: 1;
}

@keyframes buttons_enter {
    0% {opacity: 0%; bottom: 100px; top: 100px;}
    100% {opacity: 100%; bottom: 0px; top: 0px;}
}

