
.row {
    display: flex;
    padding-top: 1px;
    padding-bottom: 150px;
    background-color: lightblue;
}

.column {
    flex: 50%;
    padding: 10px;
}

button {
    background-color: white;
    color: black;
    height: 50px;
    width: 150px;
    font-size: large;
    margin-right: 10px;
    box-shadow: 5px 5px;
    cursor: pointer;
}

button:hover {
    background-color: lightgray;
    color: black;
}