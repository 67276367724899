.projects {
    color: white;
}

.selectionDiv {
    padding-bottom: 30px;
}

.row1 {
    display: flex;
    flex-wrap: wrap;
}

.singleProjectDiv {
    margin-left: 1%;
    margin-right: 1%;
    color: white;
    display: flex;
    animation: fadeIn 2s;
    flex-wrap: wrap;
    justify-content: space-between;
}

@keyframes fadeIn {
    0% { opacity: 0%; }
    100% { opacity: 100%; }
}

.leftCol {
    flex: 1;
}

.rightCol {
    flex: 1;
}

.cover {
    width: 75%;
    border-style: solid;
    border-color: white;
}








.crud1 {
    flex: 1;
    width: 50%;
    height: 100%;
}

.mines1 {
    flex: 1;
    width: 25%;
    height: 25%;
}

.mines1:hover {
    opacity: 40%;
    transition: opacity 0.6s;
}

.robo1 {
    flex: 1;
    width: 50%;
    height: 100%;
}